import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Coffee from "../assets/coffee.jpeg";

// Custom hook for intersection observer
const useIntersectionObserver = (options) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isIntersecting];
};

const FeatureTile = ({ title, description, imageSrc, isVisible, delay }) => (
  <div
    className={`bg-slate-200 p-6 rounded-lg shadow-md text-center transition-all duration-500 
                hover:shadow-lg hover:bg-amber-100 transform hover:-translate-y-1 
                ${
                  isVisible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-10"
                }`}
    style={{ transitionDelay: `${delay}ms` }}
  >
    <div className="inline-block p-3 bg-brown rounded-full mb-4">
      <img src={imageSrc} className="w-8 h-8 text-brown" />
    </div>
    <h3 className="text-lg font-semibold mb-2 text-brown">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const AboutSection = () => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.1,
    triggerOnce: true,
  });

  const features = [
    {
      imageSrc: Coffee,
      title: "Asian-Inspired Beverages",
      description:
        "Unique flavors blending asian traditions with contemporary coffee culture.",
    },
    {
      imageSrc: Coffee,
      title: "Personal Vinyl Stations",
      description:
        "Choose your soundtrack from our vast collection of records at every table.",
    },
    {
      imageSrc: Coffee,
      title: "Community Events",
      description:
        "Regular gatherings celebrating local music, art, and coffee enthusiasts.",
    },
    {
      imageSrc: Coffee,
      title: "Sustainable Sourcing",
      description:
        "Ethically sourced ingredients supporting global coffee communities.",
    },
  ];

  return (
    <section ref={ref} className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-brown text-center">
            About Spin Drip
          </h2>
          <p className="text-lg mb-12 text-gray-700 text-center">
            At Spin Drip, we're brewing up more than just exceptional coffee.
            We're creating a unique space where Asian-inspired beverages meet
            the nostalgia of vinyl records. Our mission is to offer a
            multisensory experience that delights your taste buds and your
            eardrums, all while fostering a vibrant community of coffee and
            music lovers.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {features.map((feature, index) => (
              <FeatureTile
                key={index}
                {...feature}
                isVisible={isVisible}
                delay={index * 200} // 200ms delay between each tile
              />
            ))}
          </div>
          <div className="text-center">
            <Link
              to="/about"
              className="inline-block bg-brown text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-brown transition duration-300"
            >
              Discover Our Story
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
