import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { logout } from "../services/auth";

const ProfilePage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <div className="bg-amber-50 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-md p-8 max-w-2xl mx-auto">
          <h1 className="text-3xl font-bold mb-6 text-brown">Your Profile</h1>
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Email</h2>
            <p>{user.email}</p>
          </div>
          {user.displayName && (
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Name</h2>
              <p>{user.displayName}</p>
            </div>
          )}
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Account Created</h2>
            <p>{user.metadata.creationTime}</p>
          </div>
          <button
            onClick={handleLogout}
            className="bg-brown text-white px-6 py-2 rounded hover:bg-amber-700 transition duration-300"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
