import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendContactMessage } from "../services/auth";

const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("sending");
        try {
            await sendContactMessage({ name, email, message });
            setStatus("success");
            setName("");
            setEmail("");
            setMessage("");
        } catch (error) {
            console.error("Error sending message: ", error);
            setStatus("error");
        }
    };

    return (
        <div className="bg-amber-50 min-h-screen py-12">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold mb-8 text-center text-brown">
                    Contact Us
                </h1>
                <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-8">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label
                                htmlFor="name"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-brown"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="email"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-brown"
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="message"
                                className="block text-gray-700 font-bold mb-2"
                            >
                                Message
                            </label>
                            <textarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-brown"
                                rows="6"
                                required
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="bg-brown text-white px-6 py-3 rounded-lg hover:bg-amber-900 transition duration-300"
                            disabled={status === "sending"}
                        >
                            {status === "sending"
                                ? "Sending..."
                                : "Send Message"}
                        </button>
                    </form>
                    {status === "success" && (
                        <p className="mt-4 text-green-600">
                            Your message has been sent successfully!
                        </p>
                    )}
                    {status === "error" && (
                        <p className="mt-4 text-red-600">
                            There was an error sending your message. Please try
                            again.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
