import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { X, ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "lucide-react";

const CustomLightbox = ({ images, currentIndex, onClose, onPrev, onNext }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") onClose();
      if (e.key === "ArrowLeft") onPrev();
      if (e.key === "ArrowRight") onNext();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose, onPrev, onNext]);

  useEffect(() => {
    setIsLoading(true);
    setScale(1);
    setPosition({ x: 0, y: 0 });
  }, [currentIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.5, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.5, 1));
  };

  const handleMouseDown = (e) => {
    if (scale > 1) {
      setIsDragging(true);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && scale > 1) {
      setPosition((prevPosition) => ({
        x: prevPosition.x + e.movementX,
        y: prevPosition.y + e.movementY,
      }));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      {...handlers}
    >
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white hover:text-gray-300"
      >
        <X size={24} />
      </button>
      <button
        onClick={onPrev}
        className="absolute left-4 text-white hover:text-gray-300"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={onNext}
        className="absolute right-4 text-white hover:text-gray-300"
      >
        <ChevronRight size={24} />
      </button>
      <div className="absolute bottom-4 left-4 space-x-2">
        <button
          onClick={handleZoomIn}
          className="text-white hover:text-gray-300"
        >
          <ZoomIn size={24} />
        </button>
        <button
          onClick={handleZoomOut}
          className="text-white hover:text-gray-300"
        >
          <ZoomOut size={24} />
        </button>
      </div>
      <div className="max-w-4xl max-h-90vh relative overflow-hidden">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <img
          ref={imageRef}
          src={images[currentIndex].src}
          alt={images[currentIndex].alt}
          className="max-w-full max-h-90vh object-contain transition-transform duration-200 ease-out"
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            cursor: scale > 1 ? "grab" : "auto",
          }}
          onLoad={() => setIsLoading(false)}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        />
        <div className="absolute bottom-4 left-0 right-0 text-center text-white">
          <p className="text-lg font-semibold">{images[currentIndex].alt}</p>
          <p className="text-sm">{images[currentIndex].category}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomLightbox;
