import React, { useState, useEffect } from "react";
import { fetchBlogPosts, fetchPopupEvents } from "../services/auth";
import BlogPost from "../components/BlogPost";
import PopupEvent from "../components/PopupEvent";

const BlogNewsPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [popupEvent, setPopupEvent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [posts, events] = await Promise.all([
          fetchBlogPosts(5),
          fetchPopupEvents(1),
        ]);
        setBlogPosts(posts);
        setPopupEvent(events[0]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="text-center py-10 bg-amber-50">Loading...</div>;
  }

  return (
    <div className="bg-amber-50 min-h-screen py-12 relative">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-brown">
          Spin Drip News & Events
        </h1>

        {popupEvent && (
          <div className="mb-12">
            <h2 className="text-2xl font-semibold mb-4 text-amber-700">
              Upcoming Pop-up Event
            </h2>
            <PopupEvent event={popupEvent} />
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {blogPosts.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </div>

        {blogPosts.length === 0 && (
          <p className="text-center text-gray-600">
            No blog posts available at the moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default BlogNewsPage;
