import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { fetchBackgroundImages } from "../services/auth";
import initialphoto from "../assets/initialphoto.JPG";
import vid from "../assets/spindripvid.mp4";

const HeroSection = () => {
    // const [backgroundImages, setBackgroundImages] = useState([initialphoto]);
    // const [imageIndices, setImageIndices] = useState({
    //     current: 0,
    //     next: 1,
    //     afterNext: 2,
    // });
    // const [isTransitioning, setIsTransitioning] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
    // const imageRefs = useRef([]);

    // const preloadImage = (src) => {
    //     return new Promise((resolve, reject) => {
    //         const img = new Image();
    //         img.onload = () => resolve(img);
    //         img.onerror = reject;
    //         img.src = src;
    //     });
    // };

    // const transition = useCallback(() => {
    //     if (backgroundImages.length < 3) return;

    //     setIsTransitioning(true);

    //     // Fade out current image, fade in next image
    //     imageRefs.current[imageIndices.current].style.opacity = "0";
    //     imageRefs.current[imageIndices.next].style.opacity = "1";

    //     // After transition is complete, update indices
    //     setTimeout(() => {
    //         setImageIndices((prev) => ({
    //             current: prev.next,
    //             next: prev.afterNext,
    //             afterNext: (prev.afterNext + 1) % backgroundImages.length,
    //         }));
    //         setIsTransitioning(false);
    //     }, 1000); // This should match the CSS transition time
    // }, [backgroundImages, imageIndices]);

    // useEffect(() => {
    //     const loadImages = async () => {
    //         try {
    //             const images = await fetchBackgroundImages();
    //             await Promise.all(images.map(preloadImage));
    //             setBackgroundImages((prevImages) => [...prevImages, ...images]);
    //         } catch (error) {
    //             console.error("Failed to load images:", error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     loadImages();
    // }, []);

    // useEffect(() => {
    //     if (backgroundImages.length > 2 && !isLoading) {
    //         const interval = setInterval(transition, 5000); // Change image every 5 seconds
    //         return () => clearInterval(interval);
    //     }
    // }, [backgroundImages, transition, isLoading]);

    // const renderBackgroundImages = () => {
    //     return [
    //         imageIndices.current,
    //         imageIndices.next,
    //         imageIndices.afterNext,
    //     ].map((index, i) => (
    //         <div
    //             key={index}
    //             ref={(el) => (imageRefs.current[index] = el)}
    //             className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ease-in-out"
    //             style={{
    //                 backgroundImage: `url(${
    //                     backgroundImages[index % backgroundImages.length]
    //                 })`,
    //                 opacity: i === 0 ? 1 : 0,
    //                 zIndex: 2 - i, // Ensure correct stacking order
    //             }}
    //         />
    //     ));
    // };

    return (
        <div className="h-screen overflow-hidden">
            {/* {renderBackgroundImages()} */}
            <video
                className="absolute top-0 left-0 w-full h-full object-cover "
                autoPlay
                loop
                muted
            >
                <source src={vid} type="video/mp4" />
            </video>

            <div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
            <div className="flex absolute z-20 container mx-auto px-4 py-20 h-full flex items-center right-0 left-0 top-0">
                <div className="max-w-3xl mx-auto text-center text-white">
                    <h1 className="text-4xl md:text-6xl font-bold mb-6">
                        Sip & Spin at Spin Drip
                    </h1>
                    <p className="text-xl mb-8">
                        Immerse yourself in a unique cafe experience with
                        Asian-inspired drinks and personal vinyl stations at
                        every table.
                    </p>
                    <div className="flex flex-col justify-center sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                        <Link
                            to="/gallery"
                            className="bg-brown text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-white hover:text-brown transition duration-300 text-center"
                        >
                            Explore Our Gallery
                        </Link>
                        <Link
                            to="/about"
                            className="bg-transparent border-2 border-white text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-white hover:text-brown transition duration-300 text-center"
                        >
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
