// import React from "react";
// import Coffee from "../assets/coffee.jpeg";
// import Team from "../assets/team.jpg";

// export default function Home() {
//   const menuItems = [
//     {
//       id: 1,
//       name: "Barley Latte",
//       img: Coffee,
//     },
//     {
//       id: 2,
//       name: "Salted Pandan Latte",
//       img: Coffee,
//     },
//     {
//       id: 3,
//       name: "Orange Evening",
//       img: Coffee,
//     },
//     {
//       id: 4,
//       name: "Here Comes the Sun",
//       img: Coffee,
//     },
//     {
//       id: 5,
//       name: "Purple Haze",
//       img: Coffee,
//     },
//     {
//       id: 6,
//       name: "Fairy Tail",
//       img: Coffee,
//     },
//   ];

//   const about = (
//     <div>
//       Spin Drip Cafe is a community space where you can spin records and brew
//       memories. At the heart of our space is a goal to share our passions of
//       coffee, music and community with the greater Seattle area.
//       <br />
//       <br />
//       We pour our authenticity and creativity to craft specialty drinks inspired
//       by our diverse backgrounds. We provide a unique experience where you can
//       sip your coffee while listening to tunes on your own personal record
//       player. This is an experience you can enjoy by yourself or with others,
//       thanks to the multiple handjacks.
//       <br />
//       <br />
//       This exploratory experience in discovering new music as well as listening
//       to the latest music. We have something for everything, from our drinks to
//       our music. We’d love to share our space with you all, so please come on
//       by.
//       <br />
//       <br />
//       Our goal is to open a brick and mortar shop to permanently serve our
//       community. Until then, we are hosting pop-ups throughout Seattle. We can’t
//       wait to see you around Seattle!
//     </div>
//   );

//   return (
//     <div>
//       <div className="text-9xl font-bold text-center min-h-[550px] sm:min-h-[600px]  place-content-center pb-40">
//         SPIN DRIP
//       </div>
//       <div
//         id="about"
//         className="text-9xl font-bold text-center pt-12 pb-12 place-content-center"
//       >
//         ABOUT US
//       </div>
//       <div className="flex pt-12 pb-8">
//         <div className="flex flex-column w-1/2 justify-center">
//           <div>
//             <img src={Team} alt="Team photo" />
//           </div>
//         </div>
//         <div className=" text-center w-1/2">{about}</div>
//       </div>
//       <div
//         id="menu"
//         className="text-9xl font-bold text-center pt-24 pb-24 place-content-center"
//       >
//         MENU
//       </div>
//       {menuItems.map((menu, index) =>
//         index % 2 === 0 ? (
//           <div className="flex flex-row" key={menu.id}>
//             <div className="flex w-1/2 justify-center items-center font-bold text-3xl">
//               {menu.name}
//             </div>
//             <div className="flex w-1/2">
//               <img
//                 src={menu.img}
//                 alt="Coffee"
//                 className="p-2 w-full h-full object-contain"
//               />
//             </div>
//           </div>
//         ) : (
//           <div className="flex flex-row" key={menu.id}>
//             <div className="flex w-1/2">
//               <img
//                 src={menu.img}
//                 alt="Coffee"
//                 className="p-2 w-full h-full object-contain"
//               />
//             </div>
//             <div className="flex w-1/2 justify-center items-center font-bold text-3xl">
//               {menu.name}
//             </div>
//           </div>
//         )
//       )}
//     </div>
//   );
// }

import React from "react";
import HeroSection from "../components/Hero";
import AboutSection from "../components/About";
import FeaturedOfferings from "../components/Featured";

function Home() {
  return (
    <div className="Home">
      <HeroSection />
      <AboutSection />
      {/* <FeaturedOfferings /> */}
    </div>
  );
}

export default Home;
