import React, { useState, useRef, useEffect, useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Masonry from "react-masonry-css";
import CustomLightbox from "../components/common/Lightbox";
import { getRandomGalleryImages, addImageToGallery } from "../services/auth";

const GalleryPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [lastRandomValue, setLastRandomValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const loader = useRef(null);

  const loadImages = useCallback(async () => {
    if (!hasMore || loading) {
      console.log(
        "Skipping loadImages: hasMore =",
        hasMore,
        "loading =",
        loading
      );
      return;
    }

    console.log("Starting to load images. lastRandomValue =", lastRandomValue);
    setLoading(true);
    setError(null);
    try {
      const {
        images: newImages,
        lastRandomValue: newLastRandomValue,
        hasMore: newHasMore,
      } = await getRandomGalleryImages(8, lastRandomValue);

      console.log(
        "Fetched newImages:",
        newImages.length,
        "newLastRandomValue:",
        newLastRandomValue,
        "newHasMore:",
        newHasMore
      );

      setImages((prevImages) => {
        const uniqueNewImages = newImages.filter(
          (newImage) =>
            !prevImages.some((prevImage) => prevImage.id === newImage.id)
        );
        console.log("Unique new images:", uniqueNewImages.length);
        return [...prevImages, ...uniqueNewImages];
      });
      setLastRandomValue(newLastRandomValue);
      setHasMore(newHasMore);

      if (newImages.length === 0 || !newHasMore) {
        console.log(
          "No new images or no more images, setting hasMore to false"
        );
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching images: ", error);
      setError("Failed to load images. Please try again later.");
      setHasMore(false);
    } finally {
      setLoading(false);
      console.log("Finished loading images. hasMore =", hasMore);
    }
  }, [lastRandomValue, hasMore, loading]);

  useEffect(() => {
    loadImages();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting && !loading && hasMore) {
        loadImages();
      }
    }, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loading, hasMore, loadImages]);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const handleAddImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const newImage = await addImageToGallery(file);
      setImages((prevImages) => [newImage, ...prevImages]);
    } catch (error) {
      console.error("Error adding image:", error);
    }
  };

  return (
    <div className="bg-amber-50 min-h-screen py-12 relative">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-brown">
          Spin Drip Gallery
        </h1>
        {/* <input type="file" onChange={handleAddImage} accept="image/*" /> */}

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex -ml-4 w-auto"
          columnClassName="pl-4 bg-clip-padding"
        >
          {images.map((image, index) => (
            <div key={image.id} className="mb-4">
              <LazyLoadImage
                src={image.src}
                alt={image.alt}
                effect="blur"
                className="w-full rounded-lg cursor-pointer transition duration-300 hover:opacity-90"
                onClick={() => {
                  setCurrentIndex(index);
                  setIsLightboxOpen(true);
                }}
              />
            </div>
          ))}
        </Masonry>

        <div ref={loader} className="h-10 mt-4">
          {loading && <p className="text-center">Loading more images...</p>}
          {!loading && !hasMore && images.length > 0 && (
            <p className="text-center">No more images to load</p>
          )}
          {!loading && !hasMore && images.length === 0 && (
            <p className="text-center">No images found</p>
          )}
          {error && <p className="text-center text-red-500">{error}</p>}
        </div>

        {isLightboxOpen && (
          <CustomLightbox
            images={images}
            currentIndex={currentIndex}
            onClose={() => setIsLightboxOpen(false)}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryPage;
