import React, { useState, useEffect } from "react";
import { getMerchItems } from "../services/auth";

const MerchItem = ({ item }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <img
      src={item.imageUrl}
      alt={item.name}
      className="w-full h-48 object-cover"
    />
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-1">{item.name}</h3>
      <p className="text-gray-600">${item.price.toFixed(2)}</p>
      <p className="text-sm text-gray-500 mt-2">{item.description}</p>
      <button className="mt-4 bg-amber-600 text-white px-4 py-2 rounded hover:bg-amber-700 transition duration-300">
        Add to Cart
      </button>
    </div>
  </div>
);

const MerchPage = () => {
  const [merchItems, setMerchItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMerchItems = async () => {
      try {
        const items = await getMerchItems();
        setMerchItems(items);
      } catch (error) {
        console.error("Error fetching merch items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMerchItems();
  }, []);

  if (loading) {
    return (
      <div className="text-center py-10 bg-amber-50">
        Loading merchandise...
      </div>
    );
  }

  return (
    <div className="bg-amber-50 min-h-screen py-12 relative">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-brown">
          Spin Drip Merchandise
        </h1>
        <p className="text-center mb-8 text-gray-600">
          Take a piece of Spin Drip home with you! Check out our exclusive
          merchandise.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {merchItems.map((item) => (
            <MerchItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MerchPage;
