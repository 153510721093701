import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  doc,
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  updateDoc,
  deleteDoc,
  where,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, listAll } from "firebase/storage";
import { auth, db, storage } from "../firebase/fire";

// Sign up function
export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

// Login function
export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

// Logout function
export const logout = () => {
  return signOut(auth);
};

// Subscribe to auth state changes
export const onAuthStateChange = (callback) => {
  return onAuthStateChanged(auth, callback);
};

// Get current user
export const getCurrentUser = () => {
  return auth.currentUser;
};

//  function to add email to newsletter subscribers
export const addNewsletterSubscriber = async (email) => {
  try {
    const docRef = await addDoc(collection(db, "newsletter_subscribers"), {
      email: email,
      subscribed_at: new Date(),
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};

//  function to fetch images to gallery
export const getRandomGalleryImages = async (
  pageSize,
  lastRandomValue = null
) => {
  console.log(
    "getRandomGalleryImages called with pageSize:",
    pageSize,
    "lastRandomValue:",
    lastRandomValue
  );

  const imagesRef = collection(db, "gallery_images");

  // Generate a new random value for this batch
  const randomValue =
    lastRandomValue === null ? Math.random() : lastRandomValue;
  console.log("Using randomValue:", randomValue);

  // First query: Get pageSize + 1 images
  let q = query(
    imagesRef,
    where("randomValue", ">=", randomValue),
    orderBy("randomValue"),
    limit(pageSize + 1)
  );

  let querySnapshot = await getDocs(q);
  console.log("Initial query returned", querySnapshot.docs.length, "documents");

  let hasMore = querySnapshot.docs.length > pageSize;
  let images = [];

  // If we don't have enough results, wrap around to the beginning
  if (querySnapshot.docs.length < pageSize) {
    console.log("Not enough results, wrapping around");
    const wrapAroundQuery = query(
      imagesRef,
      orderBy("randomValue"),
      limit(pageSize - querySnapshot.docs.length)
    );
    const wrapAroundSnapshot = await getDocs(wrapAroundQuery);
    console.log(
      "Wrap-around query returned",
      wrapAroundSnapshot.docs.length,
      "documents"
    );
    querySnapshot = {
      docs: [...querySnapshot.docs, ...wrapAroundSnapshot.docs],
      size: querySnapshot.docs.length + wrapAroundSnapshot.docs.length,
    };

    // Check if we've wrapped around and got back to where we started
    if (
      wrapAroundSnapshot.docs.length > 0 &&
      wrapAroundSnapshot.docs[wrapAroundSnapshot.docs.length - 1].data()
        .randomValue >= randomValue
    ) {
      hasMore = false;
    }
  }

  // Process the documents
  for (let i = 0; i < Math.min(pageSize, querySnapshot.docs.length); i++) {
    const doc = querySnapshot.docs[i];
    const imageData = doc.data();
    const imageUrl = await getDownloadURL(ref(storage, imageData.path));
    images.push({
      id: doc.id,
      src: imageUrl,
      alt: imageData.alt || "Gallery Image",
      randomValue: imageData.randomValue,
    });
  }

  const lastDoc =
    querySnapshot.docs[Math.min(pageSize, querySnapshot.docs.length) - 1];
  const newLastRandomValue = lastDoc ? lastDoc.data().randomValue : null;

  console.log(
    "Returning",
    images.length,
    "images, hasMore:",
    hasMore,
    "newLastRandomValue:",
    newLastRandomValue
  );

  return {
    images,
    lastRandomValue: newLastRandomValue,
    hasMore,
  };
};

// function to add images to the gallery
export const addImageToGallery = async (file, alt) => {
  try {
    // 1. Upload the image to Firebase Storage
    const storageRef = ref(storage, `gallery_images/${file.name}`);
    await uploadBytes(storageRef, file);

    // 2. Get the download URL
    const downloadURL = await getDownloadURL(storageRef);

    // 3. Generate a random value
    const randomValue = Math.random();

    // 4. Add a new document to Firestore
    const docRef = await addDoc(collection(db, "gallery_images"), {
      path: `gallery_images/${file.name}`,
      alt: alt || "Gallery Image",
      createdAt: new Date().toISOString(),
      randomValue: randomValue,
    });

    return {
      id: docRef.id,
      src: downloadURL,
      alt: alt || "Gallery Image",
      createdAt: new Date().toISOString(),
      randomValue: randomValue,
    };
  } catch (error) {
    console.error("Error adding image to gallery:", error);
    throw error;
  }
};

// function to fetch vinyl collection
export const getVinylRecords = async (lastDoc = null, pageSize = 20) => {
  try {
    const vinylRef = collection(db, "vinyls");
    let q = query(vinylRef, orderBy("artist"), limit(pageSize));

    if (lastDoc) {
      q = query(
        vinylRef,
        orderBy("artist"),
        startAfter(lastDoc),
        limit(pageSize)
      );
    }

    const querySnapshot = await getDocs(q);
    const vinylRecords = [];

    for (const doc of querySnapshot.docs) {
      const recordData = doc.data();
      let coverArtUrl = recordData.coverArt;

      // If coverArt is a storage path, get the download URL
      if (recordData.coverArt && !recordData.coverArt.startsWith("http")) {
        coverArtUrl = await getDownloadURL(ref(storage, recordData.coverArt));
      }

      vinylRecords.push({
        id: doc.id,
        ...recordData,
        coverArt: coverArtUrl,
      });
    }

    return {
      records: vinylRecords,
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
      hasMore: querySnapshot.docs.length === pageSize,
    };
  } catch (error) {
    console.error("Error fetching vinyl records:", error);
    throw error;
  }
};

// function to add to the vinyl collection
export const addVinyl = async (email) => {
  try {
    const docRef = await addDoc(collection(db, "vinyls"), {
      email: email,
      subscribed_at: new Date(),
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};

// function to fetch merch collection
export const getMerchItems = async () => {
  try {
    const merchRef = collection(db, "merch");
    const snapshot = await getDocs(merchRef);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching merch items:", error);
    throw error;
  }
};

// function to add to the merch collection
export const addMerch = async (email) => {
  try {
    const docRef = await addDoc(collection(db, "merch"), {
      email: email,
      subscribed_at: new Date(),
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};

// function to fetch menu
export const getMenu = async () => {
  try {
    const merchRef = collection(db, "menu");
    const snapshot = await getDocs(merchRef);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching merch items:", error);
    throw error;
  }
};

// function to add to the menu
export const addMenu = async (email) => {
  try {
    const docRef = await addDoc(collection(db, "menu"), {
      email: email,
      subscribed_at: new Date(),
    });
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e;
  }
};

// function to fetch background images
export const fetchBackgroundImages = async () => {
  try {
    const galleryRef = ref(storage, "gallery_images");
    const fileList = await listAll(galleryRef);

    const imageUrls = await Promise.all(
      fileList.items.map(async (item) => {
        const fullPath = `gallery_images/${item.name}`;
        const fileRef = ref(storage, fullPath);
        return getDownloadURL(fileRef);
      })
    );

    return imageUrls;
  } catch (error) {
    console.error("Error fetching background images:", error);
    return [];
  }
};

// Blog post functions
export const fetchBlogPosts = async (limitCount = 5) => {
  const q = query(
    collection(db, "blogPosts"),
    orderBy("date", "desc"),
    limit(limitCount)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addBlogPost = async (postData) => {
  return await addDoc(collection(db, "blogPosts"), postData);
};

export const updateBlogPost = async (postId, postData) => {
  const postRef = doc(db, "blogPosts", postId);
  return await updateDoc(postRef, postData);
};

export const deleteBlogPost = async (postId) => {
  const postRef = doc(db, "blogPosts", postId);
  return await deleteDoc(postRef);
};

// Popup event functions
export const fetchPopupEvents = async (limitCount = 1) => {
  const q = query(
    collection(db, "popupEvents"),
    orderBy("date", "asc"),
    limit(limitCount)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addPopupEvent = async (eventData) => {
  return await addDoc(collection(db, "popupEvents"), eventData);
};

export const updatePopupEvent = async (eventId, eventData) => {
  const eventRef = doc(db, "popupEvents", eventId);
  return await updateDoc(eventRef, eventData);
};

export const deletePopupEvent = async (eventId) => {
  const eventRef = doc(db, "popupEvents", eventId);
  return await deleteDoc(eventRef);
};

// Image upload function
export const uploadImage = async (file, path) => {
  const storageRef = ref(storage, path);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

// Contact form function
export const sendContactMessage = async (messageData) => {
  return await addDoc(collection(db, "contactMessages"), {
    ...messageData,
    timestamp: serverTimestamp(),
  });
};
