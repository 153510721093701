import React from "react";
import { Coffee, Music, Users, Leaf } from "lucide-react";
import Team from "../assets/team.jpg";
import Michael from "../assets/michael.jpeg";
import Tracy from "../assets/Tracy.jpeg";
import Andrew from "../assets/andrew1.jpeg";

const AboutUsPage = () => {
    return (
        <div className="bg-amber-50 relative">
            {/* Hero Section */}
            <section className="relative bg-brown text-white py-20">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl md:text-5xl font-bold mb-4">
                        Our Story
                    </h1>
                    <p className="text-xl mb-8">
                        Brewing Harmony: Where Coffee Meets Vinyl
                    </p>
                </div>
                <div
                    className="absolute bottom-0 left-0 right-0 h-16 bg-amber-50"
                    style={{ clipPath: "polygon(0 100%, 100% 0, 100% 100%)" }}
                ></div>
            </section>

            {/* Mission Section */}
            <section className="py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-center">
                        Our Mission
                    </h2>
                    <p className="text-lg mb-8 text-center max-w-3xl mx-auto">
                        At Spin Drip, we're on a mission to create a unique
                        space where the rich traditions of Asian coffee culture
                        harmonize with the soulful rhythms of vinyl records. We
                        believe in crafting experiences that delight all senses,
                        fostering community, and celebrating the artistry in
                        every cup and every track.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {[
                            {
                                icon: Coffee,
                                title: "Craft Coffee",
                                description:
                                    "Meticulously sourced and expertly brewed Asian-inspired beverages",
                            },
                            {
                                icon: Music,
                                title: "Vinyl Vibes",
                                description:
                                    "Curated playlist of records for a unique auditory experience",
                            },
                            {
                                icon: Users,
                                title: "Community Hub",
                                description:
                                    "A welcoming space for coffee lovers and music enthusiasts alike",
                            },
                            {
                                icon: Leaf,
                                title: "Sustainability",
                                description:
                                    "Committed to ethical sourcing and eco-friendly practices",
                            },
                        ].map((item, index) => (
                            <div key={index} className="text-center">
                                <item.icon className="w-12 h-12 mx-auto mb-4 text-amber-600" />
                                <h3 className="text-xl font-semibold mb-2">
                                    {item.title}
                                </h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Story Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-center">
                        Our Journey
                    </h2>
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                            <img
                                src={Team}
                                alt="Spin Drip Founders"
                                className="rounded-lg shadow-lg"
                            />
                        </div>
                        <div className="md:w-1/2">
                            <p className="text-lg mb-4">
                                Spin Drip was born from a shared passion for
                                exceptional coffee and vintage vinyl. Our
                                founders, Tracy, Michael, and Andrew, met and
                                bonded over their love for both mediums. They
                                dreamed of a place where people could enjoy the
                                perfect brew while immersing themselves in the
                                warm, rich sounds of vinyl records.
                            </p>
                            <p className="text-lg">
                                In 2024, that dream started to became a reality
                                when Spin Drip hosted its first pop up. Since
                                then, we've been serving up unique,
                                Asian-inspired coffee creations and curating an
                                ever-growing collection of vinyl for our
                                customers to enjoy. Our journey is just
                                beginning, and we're excited to continue growing
                                with our community of coffee and music lovers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Team Section */}
            <section className="py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-center">
                        Meet Our Team
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {[
                            {
                                name: "Tracy Pham",
                                role: "Co-Founder & Head of Operations",
                                image: Tracy,
                            },
                            {
                                name: "Michael Kang",
                                role: "Co-Founder & Music Curator",
                                image: Michael,
                            },
                            {
                                name: "Andrew Min",
                                role: "Co-Founder & Master Roaster",
                                image: Andrew,
                            },
                        ].map((member, index) => (
                            <div key={index} className="text-center">
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    className="w-48 h-48 rounded-full mx-auto mb-4 object-cover"
                                />
                                <h3 className="text-xl font-semibold mb-2">
                                    {member.name}
                                </h3>
                                <p className="text-brown">{member.role}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Call to Action */}
            <section className="py-16 bg-brown text-white">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4">
                        Come Experience Spin Drip
                    </h2>
                    <p className="text-xl mb-8">
                        Join us for a cup of exceptional coffee and the perfect
                        vinyl soundtrack.
                    </p>
                    <button className="bg-white text-brown px-8 py-3 rounded-full font-semibold text-lg hover:bg-amber-100 transition duration-300">
                        Check out our Pop Up Schedule
                    </button>
                </div>
            </section>
        </div>
    );
};

export default AboutUsPage;
