import React, { useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaMailBulk,


} from "react-icons/fa";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/tanRoundedBack.png";
import { addNewsletterSubscriber } from "../../services/auth";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addNewsletterSubscriber(email);
      setSubscriptionMessage("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      console.error("Subscription error:", error);
      setSubscriptionMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      // Reset the message after 3 seconds
      setTimeout(() => setSubscriptionMessage(""), 3000);
    }
  };

  return (
    <footer className="bg-brown text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h2 className="flex items-center text-xl font-bold mb-5 gap-3">
              <img src={footerLogo} alt="Logo" className="max-w-[50px]" />
              SPIN DRIP
            </h2>
            <div className="flex items-center gap-3">
              <FaLocationArrow />
              <p>SEATTLE, WA</p>
            </div>
            <div className="flex items-center gap-3 mt-3">
  <FaMailBulk />
  <a href="mailto:spindripcafe@gmail.com">spindripcafe@gmail.com</a>
</div>
            {/* Social Handle */}
            <div className="flex items-center gap-3 mt-4">
              <a href="https://www.instagram.com/spindripcafe/" target="_blank">
                <FaInstagram className="text-3xl" />
              </a>
              <a href="https://m.facebook.com/61554119925346/" target="_blank">
                <FaFacebook className="text-3xl" />
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to="/" className="hover:text-blue-300">
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="hover:text-blue-300">
                  About
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contact" className="hover:text-blue-300">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4">
            <h3 className="text-lg font-semibold mb-2">
              Subscribe to Our Newsletter
            </h3>
            <form onSubmit={handleSubscribe} className="flex flex-col">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="px-3 py-2 mb-2 text-gray-800 rounded"
              />
              <button
                type="submit"
                className="bg-blue-400 hover:bg-blue-600 px-4 py-2 rounded disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? "Subscribing..." : "Subscribe"}
              </button>
            </form>
            {subscriptionMessage && (
              <p
                className={`mt-2 ${
                  subscriptionMessage.includes("error")
                    ? "text-red-400"
                    : "text-green-400"
                }`}
              >
                {subscriptionMessage}
              </p>
            )}
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-sm text-center">
          <p>&copy; {currentYear} SPIN DRIP CAFE. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
