import React, { useState } from "react";
import { Coffee, Leaf, IceCream, Music } from "lucide-react";

const MenuItem = ({ name, description, price }) => (
  <div className="border-b border-brown py-4">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-lg font-semibold">{name}</h3>
      {/* <span className="text-brown font-bold">${price.toFixed(2)}</span> */}
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

const MenuSection = ({ title, items, icon: Icon }) => (
  <div className="mb-12">
    <div className="flex items-center mb-6">
      <Icon className="w-8 h-8 mr-2 text-brown" />
      <h2 className="text-2xl font-bold">{title}</h2>
    </div>
    {items.map((item, index) => (
      <MenuItem key={index} {...item} />
    ))}
  </div>
);

const MenuPage = () => {
  const [activeCategory, setActiveCategory] = useState("coffee");

  const menuData = {
    coffee: {
      title: "Signature Coffees",
      icon: Coffee,
      items: [
        {
          name: "Barley Latte",
          description: "Roasted barley milk with a hint of misugaru",
          price: 6,
        },
        {
          name: "Salted Pandan Latte",
          description: "Creamy, salty, and earthy pandan",
          price: 6.0,
        },
        {
          name: "Orange Evening",
          description: "Cinnamon meets vanilla with zesty-orange notes",
          price: 6.0,
        },
      ],
    },
    tea: {
      title: "Signature Teas",
      icon: Leaf,
      items: [
        {
          name: "Lily Pad",
          description:
            "Refreshing harmony between sweet strawberry-guava notes, savory coconut water, and creamy matcha",
          price: 6.0,
        },
        {
          name: "Purple Haze",
          description:
            "Colorful mix of butterfly pea tea, chrysanthemum tea with sharp and tart flavors from lime, grape and mint",
          price: 6.0,
        },
        {
          name: "Sunset",
          description:
            "Fragrant hibiscus tea with hints of orange, grapefruit and thyme",
          price: 6.0,
        },
      ],
    },
    desserts: {
      title: "Sweet Treats",
      icon: IceCream,
      items: [
        // {
        //   name: "Matcha Tiramisu",
        //   description: "Layered matcha cake with mascarpone cream",
        //   price: 6.5,
        // },
        // {
        //   name: "Black Sesame Cheesecake",
        //   description: "Creamy cheesecake with nutty black sesame",
        //   price: 6.0,
        // },
        // {
        //   name: "Mochi Donut",
        //   description: "Chewy rice flour donut with various toppings",
        //   price: 3.5,
        // },
        // {
        //   name: "Pandan Waffle",
        //   description: "Crispy waffle infused with pandan leaf essence",
        //   price: 5.5,
        // },
        {
          name: "Coming Soon",
          description: "",
          price: 6.5,
        },
      ],
    },
  };

  return (
    <div className="bg-amber-50 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-brown">
          Our Menu
        </h1>

        <div className="flex justify-center mb-8">
          {Object.keys(menuData).map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`mx-2 px-4 py-2 rounded-full ${
                activeCategory === category
                  ? "bg-brown text-white"
                  : "bg-white text-brown hover:bg-amber-100"
              } transition duration-300`}
            >
              {menuData[category].title}
            </button>
          ))}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <MenuSection {...menuData[activeCategory]} />
        </div>

        <div className="mt-12 bg-amber-100 rounded-lg p-6 text-center">
          <div className="flex justify-center items-center mb-4">
            <Music className="w-8 h-8 mr-2 text-brown" />
            <h2 className="text-2xl font-bold">Vinyl Experience</h2>
          </div>
          <p className="text-lg mb-4">
            Enhance your visit with our unique vinyl listening experience.
            Choose from our curated collection to enjoy with your drinks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
