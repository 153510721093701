import React from "react";
import { format } from "date-fns";

const BlogPost = ({ post }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      {post.imageUrl && (
        <img
          src={post.imageUrl}
          alt={post.title}
          className="w-full h-48 object-cover"
        />
      )}
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-2 text-amber-800">{post.title}</h2>
        <p className="text-gray-600 mb-4">
          {format(post.date.toDate(), "MMMM d, yyyy")}
        </p>
        <p className="text-gray-800 mb-4">{post.excerpt}</p>
        <a
          href={`/blog/${post.id}`}
          className="text-amber-600 hover:text-amber-800 font-semibold"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default BlogPost;
