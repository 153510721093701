import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X, User } from "lucide-react";
import { useAuth } from "../../context/authContext";
import whitelogo from "../../assets/whiteOutline.png";
import brownlogo from "../../assets/brownOutline.png";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const { user, loading } = useAuth();
    const brownTextPaths = [
        "/gallery",
        "/merch",
        "/vinyl",
        "/currentEvents",
        "/auth",
        "/contact",
    ];
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) return null;

    const navItems = [
        // { name: "Home", path: "/" },
        { name: "ABOUT", path: "/about" },
        // { name: "Menu", path: "/menu" },
        { name: "GALLERY", path: "/gallery" },
        // { name: "VINYL", path: "/vinyl" },
        // { name: "MERCH", path: "/merch" },
        // { name: "CURRENT", path: "/currentEvents" },
    ];

    return (
        <header
            className={`flex relative text-white overflow-hidden ${
                brownTextPaths.includes(location.pathname) && !isMenuOpen
                    ? "bg-amber-50"
                    : location.pathname === "/about"
                    ? "bg-brown"
                    : "bg-transparent"
            }`}
            style={{ zIndex: 1000 }}
        >
            <div className="w-full mx-auto px-12 py-4">
                <div className="flex justify-between items-center py-4">
                    <Link
                        to="/"
                        className={`flex text-3xl font-bold gap-3 ${
                            brownTextPaths.includes(location.pathname) &&
                            !isMenuOpen
                                ? "text-brown"
                                : ""
                        }`}
                        style={{ zIndex: 1005 }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <img
                            src={
                                brownTextPaths.includes(location.pathname) &&
                                !isMenuOpen
                                    ? brownlogo
                                    : whitelogo
                            }
                            alt="Logo"
                            className="max-w-[55px]"
                        />
                        SPIN DRIP
                    </Link>

                    <nav
                        className={`hidden md:flex space-x-4 items-center ${
                            brownTextPaths.includes(location.pathname)
                                ? "text-brown"
                                : ""
                        }`}
                    >
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className={`hover:underline hover:underline-offset-8 transition duration-300 
                ${
                    location.pathname === item.path
                        ? "underline underline-offset-8"
                        : ""
                }`}
                            >
                                {item.name}
                            </Link>
                        ))}
                        {user ? (
                            <Link
                                to="/profile"
                                className="bg-brown text-white px-4 py-2 rounded flex items-center hover:text-amber-900 transition duration-300"
                            >
                                <User size={20} className="mr-2" />
                                {user.displayName || user.email}
                            </Link>
                        ) : (
                            <Link
                                to="/auth"
                                className="bg-brown text-white px-4 py-2 rounded hover:bg-amber-900 transition duration-300"
                            >
                                LOGIN
                            </Link>
                        )}
                    </nav>

                    <button
                        className={`md:hidden ${
                            brownTextPaths.includes(location.pathname) &&
                            !isMenuOpen
                                ? "text-brown"
                                : ""
                        }`}
                        style={{ zIndex: 1005 }}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>

                {isMenuOpen && (
                    <div
                        className="fixed inset-0 bg-brown flex flex-col justify-center items-center"
                        style={{ zIndex: 1004 }}
                    >
                        <nav className="w-full max-w-md">
                            {navItems.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.path}
                                    className={`block text-center py-4 text-white text-xl hover:text-amber-900 transition duration-300 ${
                                        location.pathname === item.path
                                            ? "underline underline-offset-4"
                                            : ""
                                    }`}
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                            {user ? (
                                <Link
                                    to="/profile"
                                    className="block py-4 text-center text-white text-xl hover:text-amber-900 transition duration-300"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    <User size={24} className="inline mr-2" />
                                    {user.displayName || user.email}
                                </Link>
                            ) : (
                                <Link
                                    to="/auth"
                                    className="block py-4 text-center text-white text-xl hover:text-amber-900 transition duration-300"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    LOGIN
                                </Link>
                            )}
                        </nav>
                    </div>
                )}
            </div>
        </header>
        // <>
        //   <div className="py-4 px-8 shadow-md bg-slate-50 duration-200">
        //     <div>
        //       <div className="flex justify-between items-center">
        //         <div>
        //           <a
        //             href="#"
        //             className="font-bold text-2xl sm:text-3xl flex gap-2 items-center"
        //           >
        //             <img
        //               src={Logo}
        //               alt="Logo"
        //               className="p-2 w-24 h-24 object-contain"
        //             />
        //           </a>
        //         </div>
        //         <div className="flex justify-between items-center gap-4">
        //           <ul className="hidden sm:flex items-center gap-4">
        //             {Menu.map((menu) => (
        //               <li key={menu.id}>
        //                 <a
        //                   href={menu.link}
        //                   className="inline-block py-4 px-4 hover:text-yellow-500"
        //                 >
        //                   {menu.name}
        //                 </a>
        //               </li>
        //             ))}
        //           </ul>
        //           {/* <button className="bg-gradient-to-r from-primary to-secondary hover:scale-105 duration-200 text-white py-1 px-4 rounded-full flex items-center gap-3">
        //             Order */}
        //           {/* <FaCartShopping className="text-xl text-white drop-shadow-sm cursor-pointer" /> */}
        //           {/* </button> */}
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
    );
};

export default Navbar;
