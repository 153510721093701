import React, { useState, useEffect } from "react";
import { getVinylRecords } from "../services/auth";

const VinylRecord = ({ record }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <img
      src={record.coverArt}
      alt={`${record.title} by ${record.artist}`}
      className="w-full h-48 object-cover"
    />
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-1">{record.title}</h3>
      <p className="text-gray-600">{record.artist}</p>
      <p className="text-sm text-gray-500 mt-2">{record.genre}</p>
    </div>
  </div>
);

const VinylPage = () => {
  const [records, setRecords] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadRecords = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const {
        records: newRecords,
        lastDoc: newLastDoc,
        hasMore: newHasMore,
      } = await getVinylRecords(lastDoc);
      setRecords((prevRecords) => [...prevRecords, ...newRecords]);
      setLastDoc(newLastDoc);
      setHasMore(newHasMore);
    } catch (error) {
      console.error("Error loading vinyl records:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRecords();
  }, []);

  if (loading) {
    return (
      <div className="text-center py-10 bg-amber-50">
        Loading vinyl collection...
      </div>
    );
  }

  return (
    <div className="bg-amber-50 min-h-screen py-12 relative">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-center text-brown">
          Our Vinyl Collection
        </h1>
        <p className="text-center mb-8 text-gray-600">
          Explore our curated selection of vinyl records available for your
          listening pleasure at Spin Drip.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {records.map((record) => (
            <VinylRecord key={record.id} record={record} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VinylPage;
