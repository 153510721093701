import React from "react";
import { format } from "date-fns";

const PopupEvent = ({ event }) => {
  return (
    <div className="bg-amber-100 rounded-lg shadow-md p-6">
      <h3 className="text-xl font-bold mb-2 text-amber-800">{event.title}</h3>
      <p className="text-gray-700 mb-2">
        <strong>Date:</strong> {format(event.date.toDate(), "MMMM d, yyyy")}
      </p>
      <p className="text-gray-700 mb-2">
        <strong>Time:</strong> {event.time}
      </p>
      <p className="text-gray-700 mb-2">
        <strong>Location:</strong> {event.location}
      </p>
      <p className="text-gray-800 mb-4">{event.description}</p>
      {event.registrationLink && (
        <a
          href={event.registrationLink}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-amber-600 text-white px-4 py-2 rounded hover:bg-amber-700 transition duration-300"
        >
          Register Now
        </a>
      )}
    </div>
  );
};

export default PopupEvent;
